// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Ylqpc0JSFTk0GOnprJIu{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: transparent;
  border: none;
}
  .k8vRAc20eWArwubrQqA2{
    text-align: center;
    word-break: break-word;
    width: 80%;
  }
`, "",{"version":3,"sources":["webpack://./src/features/OpenModalButton/ui/Mobile/openModalButtonMobile.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,WAAW;EACX,6BAA6B;EAC7B,YAAY;AAMd;EALE;IACE,kBAAkB;IAClB,sBAAsB;IACtB,UAAU;EACZ","sourcesContent":[".openModalButton{\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  width: 100%;\n  background-color: transparent;\n  border: none;\n  &__name{\n    text-align: center;\n    word-break: break-word;\n    width: 80%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"openModalButton": `Ylqpc0JSFTk0GOnprJIu`,
	"openModalButton__name": `k8vRAc20eWArwubrQqA2`
};
export default ___CSS_LOADER_EXPORT___;
