// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vh70YB0RVuaw7ghvgpEF{
  height: 100dvh;
  width: 100vw;
  position: relative;
  overflow: hidden;
}
  .TQUlA2RURmWh8VYOJMoj{
    animation-name: yZCgdLBQpcQRnBLCp2X5;
  }
  .uJEiUNqAuGc4_YJWWcFV{
    animation-name: gIcINo1_NziolBBDkcnZ;
  }
  .Y890wfGTPLoY0fr4vPKg{
    animation-name: lHwAuzvaWW39eQLOaeOg;
  }
  .AX3IszcPWdFtON9ow4u1{
    transform: rotate(90deg);
    position:absolute;
    z-index:0;
    top:3dvh;
    right:-7dvh;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-timing-function:linear;
  }
  .sflUe1w263FwYXS9kxWO{
    transform: rotate(90deg);
    position:absolute;
    z-index:0;
    left:-7dvh;
    bottom:0;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-timing-function:linear;
  }
  .jjE6d6mwxp4j21irdsqc{
    position:absolute;
    z-index:0;
    right:40%;
    bottom:40%;
  }

@keyframes yZCgdLBQpcQRnBLCp2X5  {
  from {
    height: 40dvh;
  }
  to {
    height: 41dvh;
  }
}

@keyframes lHwAuzvaWW39eQLOaeOg  {
  from {
    height: 20dvh;
  }
  to {
    height: 19dvh;
  }
}

@keyframes gIcINo1_NziolBBDkcnZ  {
  from {
    height: 30dvh;
  }
  to {
    height: 31dvh;
  }
}
`, "",{"version":3,"sources":["webpack://./src/shared/UIkit/Background/ui/mobile/background.module.scss"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,YAAY;EACZ,kBAAkB;EAClB,gBAAgB;AAuClB;EAtCE;IACE,oCAA+B;EACjC;EACA;IACE,oCAAgC;EAClC;EACA;IACE,oCAA+B;EACjC;EACA;IACE,wBAAwB;IACxB,iBAAiB;IACjB,SAAS;IACT,QAAQ;IACR,WAAW;IACX,sBAAsB;IACtB,mCAAmC;IACnC,8BAA8B;IAC9B,gCAAgC;EAClC;EAEA;IACE,wBAAwB;IACxB,iBAAiB;IACjB,SAAS;IACT,UAAU;IACV,QAAQ;IACR,sBAAsB;IACtB,mCAAmC;IACnC,8BAA8B;IAC9B,gCAAgC;EAClC;EACA;IACE,iBAAiB;IACjB,SAAS;IACT,SAAS;IACT,UAAU;EACZ;;AAGF;EACE;IACE,aAAa;EACf;EACA;IACE,aAAa;EACf;AACF;;AAEA;EACE;IACE,aAAa;EACf;EACA;IACE,aAAa;EACf;AACF;;AAEA;EACE;IACE,aAAa;EACf;EACA;IACE,aAAa;EACf;AACF","sourcesContent":[".background{\n  height: 100dvh;\n  width: 100vw;\n  position: relative;\n  overflow: hidden;\n  &_largeAnimation{\n    animation-name: changeSizeLarge;\n  }\n  &_mediumAnimation{\n    animation-name: changeSizeMedium;\n  }\n  &_smallAnimation{\n    animation-name: changeSizeSmall;\n  }\n  &__topFigure{\n    transform: rotate(90deg);\n    position:absolute;\n    z-index:0;\n    top:3dvh;\n    right:-7dvh;\n    animation-duration: 1s;\n    animation-iteration-count: infinite;\n    animation-direction: alternate;\n    animation-timing-function:linear;\n  }\n\n  &__bottomFigure{\n    transform: rotate(90deg);\n    position:absolute;\n    z-index:0;\n    left:-7dvh;\n    bottom:0;\n    animation-duration: 1s;\n    animation-iteration-count: infinite;\n    animation-direction: alternate;\n    animation-timing-function:linear;\n  }\n  &__centerFigure{\n    position:absolute;\n    z-index:0;\n    right:40%;\n    bottom:40%;\n  }\n}\n\n@keyframes changeSizeLarge  {\n  from {\n    height: 40dvh;\n  }\n  to {\n    height: 41dvh;\n  }\n}\n\n@keyframes changeSizeSmall  {\n  from {\n    height: 20dvh;\n  }\n  to {\n    height: 19dvh;\n  }\n}\n\n@keyframes changeSizeMedium  {\n  from {\n    height: 30dvh;\n  }\n  to {\n    height: 31dvh;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"background": `vh70YB0RVuaw7ghvgpEF`,
	"background_largeAnimation": `TQUlA2RURmWh8VYOJMoj`,
	"changeSizeLarge": `yZCgdLBQpcQRnBLCp2X5`,
	"background_mediumAnimation": `uJEiUNqAuGc4_YJWWcFV`,
	"changeSizeMedium": `gIcINo1_NziolBBDkcnZ`,
	"background_smallAnimation": `Y890wfGTPLoY0fr4vPKg`,
	"changeSizeSmall": `lHwAuzvaWW39eQLOaeOg`,
	"background__topFigure": `AX3IszcPWdFtON9ow4u1`,
	"background__bottomFigure": `sflUe1w263FwYXS9kxWO`,
	"background__centerFigure": `jjE6d6mwxp4j21irdsqc`
};
export default ___CSS_LOADER_EXPORT___;
