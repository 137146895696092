import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from 'mobx-react-lite';
import s from './desktopElementsMobile.module.scss';
import { DesktopElementsStore } from '../../model';
const DesktopElementsMobile = observer(({ ElementButton }) => {
    const { elements, fetchStatus } = DesktopElementsStore;
    if (fetchStatus === 'error') {
        return _jsx("div", { children: "error" });
    }
    if (fetchStatus === 'pending') {
        return _jsx("div", { children: "loading" });
    }
    return (_jsx("div", { className: s.desktop, children: elements.map(({ name, id, icon, type, elementId, folderId }) => (_jsx("div", { className: s.desktop__element, children: _jsx(ElementButton, { elemId: elementId ?? folderId, type: type, name: name, id: id, icon: icon }) }, `${id}_${type}`))) }));
});
export default DesktopElementsMobile;
