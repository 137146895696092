import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Header, WindowMobile, DesktopMobile } from '@widgets';
// import SVG from './svg.svg';
// import s from './mobile.module.scss';
const Mobile = () => (
// <div className={s.mobile}>
//   <SVG className={s.mobile__svg} />
//   <span className={s.mobile__text}>
//     Мобильная версия находится в разработке и будет доступна в ближайшее
//     время. Пожалуйста, зайдите на сайт через компьютер.
//   </span>
//   <span className={s.mobile__text}>Спасибо за терпение!</span>
// </div>
_jsxs("div", { style: { position: 'relative' }, children: [_jsx(Header, {}), _jsx(WindowMobile, {}), _jsx(DesktopMobile, {})] }));
export default Mobile;
