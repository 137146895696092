import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from 'mobx-react-lite';
import { WindowStore } from '../../model';
import WindowModalMobile from './WindowModalMobile';
const WindowsMobile = observer(({ CloseButton, CollapseButton, ElementButton }) => {
    const { windows, moveWindowToTop } = WindowStore;
    if (!windows.length) {
        return null;
    }
    const [{ name, order, status, id, content, type, fetchStatus }] = windows.slice(-1);
    return (_jsx(WindowModalMobile, { id: id, status: status, order: order, name: name, CloseButton: CloseButton, CollapseButton: CollapseButton, contentType: type, content: content, fetchStatus: fetchStatus, ElementButton: ElementButton, moveOnTop: moveWindowToTop }, id));
});
export default WindowsMobile;
