import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Icon, Text } from '@ui';
import { WindowStore } from '@entities';
import { observer } from 'mobx-react-lite';
import s from './openModalButtonMobile.module.scss';
const OpenModalButtonMobile = observer(({ id, icon, name, type, elemId, className = '', inFolder = false }) => {
    const { addWindow } = WindowStore;
    const handleClick = () => {
        addWindow(name, id, type, elemId);
    };
    return (_jsxs("button", { name: `${id}`, className: `${s.openModalButton} ${className}`, tabIndex: 0, onClick: handleClick, children: [_jsx(Icon, { size: 66, name: icon }), _jsx(Text, { inFolder: inFolder, className: s.openModalButton__name, children: name })] }));
});
export default OpenModalButtonMobile;
