// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vP_qvyzvOuvEtQznd09H{
  border: 2px solid black;
  overflow: hidden;
  width: 100vw;
  height: 100dvh;
  position: absolute;
  z-index: 100000;
  top:0;
}

  .JYlMax2O41Wk_uzjrIE5{
    height: calc(100% - 33px);
    overflow-y: auto;
  }

  .JnRwSFoypx2m_dXP2yJX{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .cJwznrxDrh7AccKW5i6L{
    display: flex;
    align-items: center;
    padding: 0 90px 0 5px;
  }

  .gHCptjbzczYYA1eCwUg2{
    flex-grow: 1;
    display: flex;
    justify-content: center;
  }

`, "",{"version":3,"sources":["webpack://./src/entities/Windows/ui/Mobile/windows.module.scss"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,gBAAgB;EAChB,YAAY;EACZ,cAAc;EACd,kBAAkB;EAClB,eAAe;EACf,KAAK;AAuBP;;EArBE;IACE,yBAAyB;IACzB,gBAAgB;EAClB;;EACA;IACE,WAAW;IACX,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;EACrB;;EACA;IACE,aAAa;IACb,mBAAmB;IACnB,qBAAqB;EACvB;;EACA;IACE,YAAY;IACZ,aAAa;IACb,uBAAuB;EACzB","sourcesContent":[".windowModal{\n  border: 2px solid black;\n  overflow: hidden;\n  width: 100vw;\n  height: 100dvh;\n  position: absolute;\n  z-index: 100000;\n  top:0;\n\n  &__content{\n    height: calc(100% - 33px);\n    overflow-y: auto;\n  }\n  &__message{\n    width: 100%;\n    height: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n  &__header{\n    display: flex;\n    align-items: center;\n    padding: 0 90px 0 5px;\n  }\n  &__title{\n    flex-grow: 1;\n    display: flex;\n    justify-content: center;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"windowModal": `vP_qvyzvOuvEtQznd09H`,
	"windowModal__content": `JYlMax2O41Wk_uzjrIE5`,
	"windowModal__message": `JnRwSFoypx2m_dXP2yJX`,
	"windowModal__header": `cJwznrxDrh7AccKW5i6L`,
	"windowModal__title": `gHCptjbzczYYA1eCwUg2`
};
export default ___CSS_LOADER_EXPORT___;
