import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useThemeContext } from '../../../ThemeContext';
import s from './background.module.scss';
import { Circle, OrangeLarge, OrangeMedium, OrangeSmall, RedLarge, RedMedium, RedSmall, } from '../../svg';
const BackgroundMobile = ({ children }) => {
    const { theme: { name, background }, } = useThemeContext();
    if (name === 'old') {
        return (_jsx("div", { className: s.background, style: { background }, children: children }));
    }
    return (_jsxs("div", { className: s.background, style: { background }, children: [_jsx(RedLarge, { className: `${s.background__topFigure} ${s.background_largeAnimation}` }), _jsx(RedMedium, { className: `${s.background__topFigure} ${s.background_mediumAnimation}` }), _jsx(RedSmall, { className: `${s.background__topFigure} ${s.background_smallAnimation}` }), _jsx(Circle, { className: s.background__centerFigure, width: '15%' }), _jsx(OrangeLarge, { className: `${s.background__bottomFigure} ${s.background_largeAnimation}` }), _jsx(OrangeMedium, { className: `${s.background__bottomFigure} ${s.background_mediumAnimation}` }), _jsx(OrangeSmall, { className: `${s.background__bottomFigure} ${s.background_smallAnimation}` }), children] }));
};
export default BackgroundMobile;
