// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.d1O071goVBy_hsrDGHt0{
  height: 100vh;
  width: 100vw;
  position: relative;
}
  .abx40eyItztbnk3iiUZn{
    animation-name: oQOtXv4ZMD1a8NM4qzVp;
  }
  .nXfFEe98lL3Oh2aBSHKd{
    animation-name: L2OAJECaVoZwYe56JUJm;
  }
  .nvzoPrA489hwF2Mk8Y_g{
    animation-name: gHL36E4b2Kkyocs9xYeg;
  }
  .sKb3FVUjzPDt3O6y4gK7{
    position:absolute;
    z-index:0;
    top:0;
    left:0;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-timing-function:linear;
  }
  .wgTHY4zx5w6I_3PW6ZCo{
    position:absolute;
    z-index:0;
    right:0;
    bottom:0;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-timing-function:linear;
  }
  .rfDRXVny2vRM2Km953z0{
    position:absolute;
    z-index:0;
    right:40%;
    bottom:40%;
  }

@keyframes oQOtXv4ZMD1a8NM4qzVp  {
  from {
    width: 40vw;
  }
  to {
    width: 41vw;
  }
}

@keyframes gHL36E4b2Kkyocs9xYeg  {
  from {
    width: 20vw;
  }
  to {
    width: 19vw;
  }
}

@keyframes L2OAJECaVoZwYe56JUJm  {
  from {
    width: 30vw;
  }
  to {
    width: 31vw;
  }
}
`, "",{"version":3,"sources":["webpack://./src/shared/UIkit/Background/ui/background.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,YAAY;EACZ,kBAAkB;AAqCpB;EApCE;IACE,oCAA+B;EACjC;EACA;IACE,oCAAgC;EAClC;EACA;IACE,oCAA+B;EACjC;EACA;IACE,iBAAiB;IACjB,SAAS;IACT,KAAK;IACL,MAAM;IACN,sBAAsB;IACtB,mCAAmC;IACnC,8BAA8B;IAC9B,gCAAgC;EAClC;EAEA;IACE,iBAAiB;IACjB,SAAS;IACT,OAAO;IACP,QAAQ;IACR,sBAAsB;IACtB,mCAAmC;IACnC,8BAA8B;IAC9B,gCAAgC;EAClC;EACA;IACE,iBAAiB;IACjB,SAAS;IACT,SAAS;IACT,UAAU;EACZ;;AAGF;EACE;IACE,WAAW;EACb;EACA;IACE,WAAW;EACb;AACF;;AAEA;EACE;IACE,WAAW;EACb;EACA;IACE,WAAW;EACb;AACF;;AAEA;EACE;IACE,WAAW;EACb;EACA;IACE,WAAW;EACb;AACF","sourcesContent":[".background{\n  height: 100vh;\n  width: 100vw;\n  position: relative;\n  &_largeAnimation{\n    animation-name: changeSizeLarge;\n  }\n  &_mediumAnimation{\n    animation-name: changeSizeMedium;\n  }\n  &_smallAnimation{\n    animation-name: changeSizeSmall;\n  }\n  &__topFigure{\n    position:absolute;\n    z-index:0;\n    top:0;\n    left:0;\n    animation-duration: 1s;\n    animation-iteration-count: infinite;\n    animation-direction: alternate;\n    animation-timing-function:linear;\n  }\n\n  &__bottomFigure{\n    position:absolute;\n    z-index:0;\n    right:0;\n    bottom:0;\n    animation-duration: 1s;\n    animation-iteration-count: infinite;\n    animation-direction: alternate;\n    animation-timing-function:linear;\n  }\n  &__centerFigure{\n    position:absolute;\n    z-index:0;\n    right:40%;\n    bottom:40%;\n  }\n}\n\n@keyframes changeSizeLarge  {\n  from {\n    width: 40vw;\n  }\n  to {\n    width: 41vw;\n  }\n}\n\n@keyframes changeSizeSmall  {\n  from {\n    width: 20vw;\n  }\n  to {\n    width: 19vw;\n  }\n}\n\n@keyframes changeSizeMedium  {\n  from {\n    width: 30vw;\n  }\n  to {\n    width: 31vw;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"background": `d1O071goVBy_hsrDGHt0`,
	"background_largeAnimation": `abx40eyItztbnk3iiUZn`,
	"changeSizeLarge": `oQOtXv4ZMD1a8NM4qzVp`,
	"background_mediumAnimation": `nXfFEe98lL3Oh2aBSHKd`,
	"changeSizeMedium": `L2OAJECaVoZwYe56JUJm`,
	"background_smallAnimation": `nvzoPrA489hwF2Mk8Y_g`,
	"changeSizeSmall": `gHL36E4b2Kkyocs9xYeg`,
	"background__topFigure": `sKb3FVUjzPDt3O6y4gK7`,
	"background__bottomFigure": `wgTHY4zx5w6I_3PW6ZCo`,
	"background__centerFigure": `rfDRXVny2vRM2Km953z0`
};
export default ___CSS_LOADER_EXPORT___;
