// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.JIVsJdtxFszShwbCZ7GP{
  height: 95dvh;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: flex-start;
  padding:30px 0 30px 0;
}
  .cg5edUCKSZTaFgPOmLG6{
    width: 31%;
    height: fit-content;
  }
`, "",{"version":3,"sources":["webpack://./src/entities/DesktopElements/ui/Mobile/desktopElementsMobile.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,8BAA8B;EAC9B,yBAAyB;EACzB,qBAAqB;AAKvB;EAJE;IACE,UAAU;IACV,mBAAmB;EACrB","sourcesContent":[".desktop{\n  height: 95dvh;\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  justify-content: space-between;\n  align-content: flex-start;\n  padding:30px 0 30px 0;\n  &__element{\n    width: 31%;\n    height: fit-content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"desktop": `JIVsJdtxFszShwbCZ7GP`,
	"desktop__element": `cg5edUCKSZTaFgPOmLG6`
};
export default ___CSS_LOADER_EXPORT___;
