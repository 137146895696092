// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HCHNW15DxuxPWOM38jv3{
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: none;
}
.IDQlNYQ4gisbC6g7jkwg{
  background-color: var(--red);
}
.LYWpT2t1qGMNXt6YWXoT{
  background-color: var(--yel);
}
.s9M1DuYpN7jsOeLL1RpN{
  background-color: transparent;
  height: 66px;
  width: 90px;
}
`, "",{"version":3,"sources":["webpack://./src/shared/UIkit/PanelButton/panelButton.module.scss"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,YAAY;AAChB;AACA;EACE,4BAA4B;AAC9B;AACA;EACE,4BAA4B;AAC9B;AACA;EACE,6BAA6B;EAC7B,YAAY;EACZ,WAAW;AACb","sourcesContent":[".panelButton{\n    width: 20px;\n    height: 20px;\n    border-radius: 50%;\n    border: none;\n}\n.close{\n  background-color: var(--red);\n}\n.collapse{\n  background-color: var(--yel);\n}\n.mobile{\n  background-color: transparent;\n  height: 66px;\n  width: 90px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"panelButton": `HCHNW15DxuxPWOM38jv3`,
	"close": `IDQlNYQ4gisbC6g7jkwg`,
	"collapse": `LYWpT2t1qGMNXt6YWXoT`,
	"mobile": `s9M1DuYpN7jsOeLL1RpN`
};
export default ___CSS_LOADER_EXPORT___;
