import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { Loader, Text, useThemeContext } from '@ui';
import s from './windows.module.scss';
import windowContent from '../windowContent';
// TODO: Разбить на windowModal и draggbleContainer
const WindowMobileModal = ({ id, name, CloseButton, contentType, content, fetchStatus, ElementButton, }) => {
    const { theme: { backOfElementsColor }, } = useThemeContext();
    const ContentComponent = windowContent[contentType];
    return (_jsxs("div", { className: s.windowModal, style: {
            backgroundColor: backOfElementsColor,
        }, children: [_jsxs("div", { className: s.windowModal__header, children: [_jsx(CloseButton, { id: id, type: 'mobile' }), _jsx(Text, { className: s.windowModal__title, inFolder: true, children: name })] }), _jsxs("div", { className: s.windowModal__content, children: [fetchStatus === 'pending' && (_jsx("div", { className: s.windowModal__message, children: _jsx(Loader, { size: 30 }) })), fetchStatus === 'error' && (_jsx("div", { className: s.windowModal__message, children: _jsx(Text, { size: 'Large', bold: true, children: "Error" }) })), fetchStatus === null && (_jsx(ContentComponent, { isMobile: true, ElementButton: ElementButton, ...content }))] })] }));
};
export default memo(WindowMobileModal);
