// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.YOxaZLOny6ca9ySRN6BW{
  width: 100vw;
  height: 5dvh;
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.24);
  position: relative;
  z-index: 1000;
  padding: 0 20px 0 20px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  justify-content: space-between;
}
  .WgMw04VhHzPMX5pS5W3i{
    display: flex;
    flex-direction: row;
    gap:10px
  }
`, "",{"version":3,"sources":["webpack://./src/widgets/Header/ui/header.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,YAAY;EACZ,gDAAgD;EAChD,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,aAAa;EACb,mBAAmB;EACnB,sBAAsB;EACtB,8BAA8B;AAMhC;EALE;IACE,aAAa;IACb,mBAAmB;IACnB;EACF","sourcesContent":[".header{\n  width: 100vw;\n  height: 5dvh;\n  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.24);\n  position: relative;\n  z-index: 1000;\n  padding: 0 20px 0 20px;\n  display: flex;\n  align-items: center;\n  box-sizing: border-box;\n  justify-content: space-between;\n  &__meta{\n    display: flex;\n    flex-direction: row;\n    gap:10px\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `YOxaZLOny6ca9ySRN6BW`,
	"header__meta": `WgMw04VhHzPMX5pS5W3i`
};
export default ___CSS_LOADER_EXPORT___;
